import React from "react";
import Slider from "./Slider";
import MarqueeLComList from "./MarqueeLComList";
import { BackTop } from "antd";

const HomeComponent = () => {
  return (
    <div className="container pb-5 d-flex justify-content-center">
      <div className="row w-100 d-flex justify-content-center">
        <div className="col-12">
          <p className="pt-4">
            We wish to introduce ourselves in one of the leading pharmaceutical
            distributors at Hubbali.{" "}
            <span className="bolder">YASHIKA AGENCIES</span> was started by shri
            SHAMBU YAVAGAL on 10th July 1978 under the guidance of our uncle
            Late Shri GURU SIDAPPA YAVAGAL and Late Shri BASAVRAJ YAVAGAL. We
            started with two companies as authorized dealers for I.D.P.L and
            Cosme Pharma. Year on year we have grown with providing service to
            customers and companies. We have also been appointed as stockers for
            several companies. We were first people to computerize billing and
            inventory in the year 1988.
          </p>
        </div>
        <div className="col-12">
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;
