import React from "react";
import { useState } from "react";
import LoaderTri from "../../components/Loader/LoaderTri";
import { Image } from "antd";

const ContactUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="my-5 mx-4 contact-info">
            <p className="fw-bold m-0">MUDRAA PHARMA VENTURE LLP,</p>
            <p className="fw-bold m-0">
              OPPOSITE TO SHIVAJI SCHOOL KANHERI ROAD,
            </p>
            <p className="fw-bold m-0">LATUR, 413 512 (MAHARASHTRA)</p>
          </div>

          <div className="contact mx-4">
            <p className="fw-bold m-0">Ph.: +91 9356935284, +91 9356940497</p>
            <p className="fw-bold m-0">Email: mudraapharmallp@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
