import { configureStore } from "@reduxjs/toolkit";

import UserReducer from "./UserOrders";
import userSlice from "../../../store/user-slice.js";

const UserProductstore = configureStore({
    reducer: {
        userOrders: UserReducer,
        userSlice: userSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default UserProductstore;