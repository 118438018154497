
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import "./Styles/App.css";
import { Provider } from 'react-redux';
import RoutesComponents from './Routes/RoutesComponents';
import UserProductStore from './components/customer/Userproducts/UserProductStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import { Suspense } from 'react';
import { FallbackLoading } from './components/Loaders/FallbackLoading';
import { useEffect } from 'react';
import { env } from './env';


import 'react-csv-importer/dist/index.css';


function App() {

  useEffect(() => {
    if (parseInt(env.REACT_APP_CLEAR_CACHE) === 1) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        })
      }

      // Makes sure the page reloads. Changes are only visible after you refresh.
      // window.location.reload(true)
    }
  }, [])

  return (
    <>
      <Provider store={UserProductStore}>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover

        />
        <Router>
          <Suspense fallback={<FallbackLoading />}>
            <RoutesComponents />
          </Suspense>
        </Router>
      </Provider>
    </>
  );
}

export default App;
