import { useEffect, useState } from "react";
import "./Styles/App.css";
import { Table, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cproduct from "./C_product";
import GlobalVariable from "./GlobalVariable";
import ApiHandler from "./ApiHandler";
import LoaderTri from "./components/Loader/LoaderTri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';

const PageSize = 200;

function Product() {
  const [data, setData] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [comCode, setComCode] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [Counter, setCounter] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const ApiData = (Counter = 1) => {
     ApiHandler.post({
      //url: localUrl + "api/OwnApp/GetComOrderList",
      url: "api/POPSReports/AllProduct/",
      data: {
        wClientid: GlobalVariable.wcClient + "",
        "page": Counter,
        "pageSie": PageSize
      },
      // cancelToken: source.token,
    })
      .then((result) => {
        console.log(result.data);
        const temp = [];

        result.data.forEach((element) => {
          temp.push(element);
        });

        console.log(temp);
        setData(C=>([...C,...temp]));

        if(result.data.length < PageSize)
        {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  

  useEffect(() => {
    setIsLoading(true);
    const cancelToken = ApiHandler.cancelToken;
    const source = cancelToken.source();

    //fetch data from api
    ApiData();

    return () => {
      source.cancel();
      setData([]);
    };
  }, []);

  return (
    <>
      {!isProduct && !comCode ? (
        <div className=" ">
          <h5 className="text-center mt-3">All Products</h5>
          <Button
            variant="primary"
            style={{
              float: "left",
            }}
            className="m-2 mt-4"
            onClick={() => {
              history.push("/");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
            Back
          </Button>

          <div
            className=""
            style={{
              width: "100%",
              overflowX: "scroll",
            }}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={(res)=> {
                ApiData(Counter + 1)
                setCounter(Counter + 1)
              }}
              hasMore={hasMore}
              loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>End of results</b>
                </p>
              }
            >
              <Table striped>
                <tbody>
                  {Array.isArray(data) &&
                  data.map((elm, i) => {
                    return (
                      <tr key={i}>
                         <td
                            className="compa"
                            style={{
                              color: "#2ec8a6",
                              cursor: "pointer",
                            }}
                          >
                            {elm.productName}
                          </td>

                          <td
                            className="compa"
                            style={{
                              color: "#2ec8a6",
                              cursor: "pointer",
                            }}
                          >
                            {elm.companyName}
                          </td>

                          <td
                            className="compa"
                            style={{
                              color: "#2ec8a6",
                              cursor: "pointer",
                            }}
                          >
                            {elm.case}
                          </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

            </InfiniteScroll>
            {isLoading && <LoaderTri />}
          </div>
        </div>
      ) : (
        <Cproduct
          comCode={comCode}
          setComCode={() => {
            setComCode(null);
            setIsProduct(false);
          }}
        />
      )}
      <br />
      <br />
      <br />
    </>
  );
}
export default Product;
