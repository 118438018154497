
import './Styles/App.css'
import Header from "./snippets/Products/Header";
import Product  from './Product';
// import Pfooter from './Pfooter';
import CustomFooter from './snippets/Customer/customFooter';

function ProductList(){
    return (
      <>
        <Header />
        <Product />
        <CustomFooter />
      </>
    );
}
export default ProductList;