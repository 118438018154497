import { Link } from "react-router-dom";
import Header from "../../snippets/Products/Header";

export default function Demo() {

    return (
        <>
            <Header />
            <div className="demo" style={{ textAlign: "center" }}>
                <h1 >Welcome <br />Your Now In Customer Dashboard</h1>
                <Link to="/Customer">Log Out</Link>
            </div>
        </>
    )
}