const Company = [
  {
    name: "YASHIKA AGENCIES",
    fullName: "YASHIKA AGENCIES",
    tagline: "Pharmaceutical Distributor",
    phone: "Mob: 9900255620",
    alternateNumber: ["Ph.: 0836 2365441", "2221305"],
    email: "Email: yashikaagencies@gmail.com",
    website: "Website: www.yashikaagencies.in",
    address:
      "A+B+C, Metropolis Annex <br/># 201, 1st Floor, Next to Hotel Trupti,<br/>Koppikar Road, Hubbali - 580 020",
  },
];

const BankDetails = [
  {
    label: "Bank Details",
    value: "Kotak Mahindra Bank Ltd.",
  },
  {
    label: "Account Name",
    value: "M/s YASHIKA AGENCIES",
  },
  {
    label: "A/C. No.",
    value: "4912583439",
  },
  {
    label: "IFSC Code:",
    value: "KKBK0008190",
  },
];

export { Company, BankDetails };