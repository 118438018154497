import React from "react";
// import iconPayStore from "../assets/badge-en.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faMobileScreen,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import '../Styles/footer.scss'
import { Company, BankDetails } from "../assets/Data";

function Footer() {
  console.log(BankDetails);

  return (
    <>
      <footer
        className="footer pt-4"
        style={{
          bottom: 0,
          // position: "fixed",
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.15) 0px -7px 1rem 4px",
        }}
      >
        <div>
          <div className="row m-0 footer-info">
            <div className="col-md-4 col-sm-12 mb-4">
              <p
                className="address-section small-devices-text mr-1 d-block w-100 mb-3"
                dangerouslySetInnerHTML={{ __html: Company[0].address }}
              ></p>
            </div>

            <div className="col-md-4 col-sm-12">
              <ul className="footer-list">
                <li className="mb-4 small-devices-text sm-flex-center">
                  <table className="bank-details">
                    <tbody>
                      {BankDetails.map((item, index) => (
                        <tr key={index}>
                          <td>{item.label}</td>
                          <td>{item.value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </li>
              </ul>
            </div>

            <div className="col-md-4 col-sm-12">
              <ul className="footer-list px-5">
                <li>
                  <p className="phone small-devices-text mr-1">
                    {Company[0].alternateNumber.join(", ")}
                  </p>
                </li>

                <li>
                  <p className="phone small-devices-text mr-1">
                    {Company[0].phone}
                  </p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">{Company[0].email}</p>
                </li>

                <li className="email small-devices-text">
                  <p className="mr-1">{Company[0].website}</p>
                </li>
              </ul>
            </div>

            <section className="copyright">
              <p className="small-devices-text">
                Powered By:
                <a
                  href="http://www.relysoft.in/"
                  target="_blank"
                  rel="noreferrer"
                  className="powered-by text-sm-center"
                >
                  relysoft.in
                </a>
              </p>
            </section>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
