import react, { useEffect, useState } from 'react';
import SalesManHeader from "./SalesManHeader";
import Typeahead from "../Typeahead";
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { AiOutlineDelete } from 'react-icons/ai';
import axios from 'axios';
import SalesmanDashboard from './SalesmanDashboard';
import { add, addcstName, addcstPlace, addCustomer, addProducts, } from "../../store/CustOrder";
import { UserContext } from './SalesmanDashboard';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import GlobalVariable from '../../GlobalVariable';





const productsColumns = [

    {
        header: "Product Name",
        field: "prodName",
        cols: 1,
    },
    {
        header: "Company",
        field: "companyName",
        cols: 2,
    },
    {
        header: "Pack",
        field: "case1",
        cols: 1,
    },

    {
        header: "scheme",
        field: "scheme",
        cols: 1,
    },
    {
        header: "stock",
        field: "stock",
        cols: 1,
    },
    {
        header: "mrp",
        field: "mrp",
        cols: 1,
    },
    {
        header: "rate",
        field: "rate",
        cols: 1,
    },






];
const indialOrder = {

    wClientid: GlobalVariable.W2,
    CustCode: '',
    repcode: 4,
    uniqueOrderID: GlobalVariable.W2,
    orderType: 'web',
    rclientid: null,
    urgent: 0,
    remarks: '',
    mobNo: "",
    orderItems: []

}


export default function SmOrder(props) {

    const [smShow, setSmShow] = useState(false);
    const [newProduct, setNewProduct] = useState([])
    // const [filterVal, setFilterVal] = useState('');
    const [searchApiData, setSearchApiData] = useState([]);
    const [compName, setCompName] = useState()
    const [prodName, setProdName] = useState();
    const [case1, setCase1] = useState();
    const [scheme, setScheme] = useState(0);
    const [stock, setStock] = useState();
    const [mrp, setMrp] = useState();
    const [rate, setRate] = useState();
    const [increaseprice, setIncreasePrice] = useState(0.00);
    // const [isOpen, setIsOpen] = useState(false)
    const [data, setData] = useState(0);

    // const [comp1, setComp1] = useState();
    const [prd1, setPrd1] = useState();
    const [cs1, setCs1] = useState();
    //const [sc1, setSc1] = useState("");
    const [mrp1, setMrp1] = useState();
    const [visible, setVisible] = useState(false);
    const [noOfquantity, setNoOfQuantity] = useState(1);
    // const [allData, setAllData] = useState([]);
    // const [noAvailableProduct, setNoAvailableProduct] = useState();
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('--Select--');
    const [input, setInput] = useState('');
    //  const [customer, setCustomer] = useState([]);
    // const [cstName, setCstName] = useState('');
    // const [cstPlace, setCstPlace] = useState('')
    const [order, setOrder] = useState(indialOrder);
    const [remarksMessage, setRemarksMessage] = useState("--Select--");
    const [productCode, setProductCode] = useState('');
    const [cstOrders, setcstOrders] = useState(order.cName);
    const [cstorderPlace, setCstOrderPlace] = useState();
    const [showsalesStock, setShowsalesStock] = useState([])

    const customerOrder = useSelector((state) => state.order.custcodes)
    const customerOrderName = useSelector((state) => state.order.cName)
    const customerOrderPlace = useSelector((state) => state.order.cplace)

    var grandTotalvalue = 0;
    let pickuporder = "PickUp Order";
    let deliveryOrder = "Delivery Order";
    let history = useHistory();



    console.log(cstOrders, cstorderPlace)



    useEffect(() => {

        setcstOrders(customerOrderName)
        setCstOrderPlace(customerOrderPlace)
        setOrder((v) => {
            const user = JSON.parse(localStorage.getItem('user-info'))

            const date = new Date();
            return {
                ...v, uniqueOrderID: GlobalVariable.W2 + "_" + user.code + "_" + customerOrder + "_" +
                    date.getDay() +
                    date.getMonth() +
                    date.getFullYear() +
                    date.getHours()
                    + date.getMinutes()
                    + date.getSeconds()
                    + date.getMilliseconds() * 100000,


                //   ...v, uniqueOrderID: GlobalVariable.W2 + "_" + user.code + "_" + new Date()
            }
        })
    }, [customerOrder])

    useEffect(() => {

        setOrder((v) => {
            // const user = JSON.parse(localStorage.getItem('user-info'))
            return {
                ...v, CustCode: customerOrder,
            }
            //  user && setOrder((v) => { return { ...v, CustCode: customerOrder } })
        })
    }, [customerOrder])


    const user = JSON.parse(localStorage.getItem('user-info'));
    const token = JSON.parse(localStorage.getItem('user-info'));

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user-info'))
        user && setOrder((v) => { return { ...v, repcode: user.code } })
        //user && setOrder((v) => { return { ...v, CustCode: user.code } })

    }, [])


    useEffect(() => {
        axios({
            url: "https://popsv3.relysoft.in/api/OwnAppConfigs/5009267",
            method: "GET",
            headers: {

                'authorization': "  Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1laWQiOiI1MDA5MjY3IiwibmJmIjoxNjc2NDMzMjcxLCJleHAiOjE2NzkwMjUyNzEsImlhdCI6MTY3NjQzMzI3MX0.Fbs5cVCNlmJjUUiGTlmsarR-w-9B8I3gi8flqzwZmdg",
            },
        })
            .then((res) => {
                console.log(res.data)
                console.log(res.data.showStocktoSMan)

                setShowsalesStock(res.data.showStocktoSMan)

            })
            .catch((err) => {
                console.error(err)
            });
    }, [])




    function SearchHandler(text) {

        console.log(user);
        setLoading(true);
        axios.post("https://popsv3.relysoft.in/api/Search/ProductByName/",

            {
                wClientid: GlobalVariable.W2,
                search: text,
                areaCode: user.areaCode + "" + 0
            }, {
            headers: {
                Authorization: "Bearer " + user.token,
            }
        }).then(res => {
            console.log(res.data);
            const mappedData = res.data.map(data => {

                return {
                    // ...data, stock: data.stock + ""
                    ...data, stock: showsalesStock !== true ? data.stock && data.stock > 500 ? "500+" : data.stock + ' ' : data.stock > 0 ? "YES" : "NO",
                }
            })
            setNewProduct(mappedData);
            setSearchApiData(res.data);


        })
            .catch(err => {
                console.error(err);

            }).finally(() => {
                setLoading(false);

            });
    }

    useEffect(() => {
        setRemarksMessage()

    }, [])

    useEffect(() => {
        let total = 0;
        console.log({ orderItems: order.orderItems });
        order.orderItems && order.orderItems.forEach(data => {
            console.log();
            total += parseFloat(data.noOfquantity) * parseFloat(data.mrp1);
        })
        setIncreasePrice(total);
        return () => {

        };
    }, [order.orderItems])


    function addValuehandler(e) {
        setSmShow(false)

        console.log(grandTotalvalue += increaseprice * e.target.value)
        setOrder((v) => {

            return {
                ...order,
                orderItems: [...v.orderItems, {
                    slno: data + 1,
                    PrdCode: productCode,
                    comp1: compName,
                    prd1: prodName,
                    cs1: case1,
                    sc1: scheme,
                    mrp1: mrp,
                    noOfquantity,
                    showTime: new Date()
                    // showTime:
                    //     date.getDay() +
                    //     date.getMonth() +
                    //     date.getFullYear() +
                    //     date.getHours()
                    //     + date.getMinutes()
                    //     + date.getSeconds()
                    //     + date.getMilliseconds(),



                }]
            }
        })
        // setAllData([...allData, {
        //     slno: data + 1,
        //     comp1: compName,
        //     prd1: prodName,
        //     cs1: case1,
        //     sc1: scheme,
        //     mrp1: mrp,
        //     noOfquantity,

        // }])

        setData(data + 1)
    }

    function addValuehandlerr(e) {


        if (e.key === 'Enter') {
            setSmShow(false)

            console.log(grandTotalvalue += increaseprice * e.target.value)
            const date = new Date();
            setOrder({
                ...order,
                orderItems: [...order.orderItems, {
                    slno: data + 1,
                    PrdCode: productCode,
                    comp1: compName,
                    prd1: prodName,
                    cs1: case1,
                    sc1: scheme,
                    mrp1: mrp,
                    noOfquantity,
                    showTime: new Date()
                    // showTime:
                    //     date.getDay() +
                    //     date.getMonth() +
                    //     date.getFullYear() +
                    //     date.getHours()
                    //     + date.getMinutes()
                    //     + date.getSeconds()
                    //     + date.getMilliseconds(),


                }]
            })

            setData(data + 1)
        }
    }

    function deletehandler(record) {
        console.log({ record, order });
        // setAllData(
        //     allData.filter((product) => product.slno !== record.slno)
        // );
        setOrder(
            {
                ...order, orderItems: order.orderItems.filter((product) => product.slno !== record.slno)
            });

    };
    const [productSeach, setProductSeach] = useState({
        options: [],
        selected: [],
        search: ""
    })


    // useEffect(() => {
    //     const Products = window.localStorage.getItem("Products");
    //     const saveData = JSON.parse(Products);
    //     setOrder((v) => {
    //         return {
    //             ...v, orderItems: saveData.orderItem,
    //         }
    //     }
    //     );
    //     // setAllData(saveData.allData);
    //     setPrd1(saveData.prd1);
    //     setCs1(saveData.cs1);
    //     setMrp1(saveData.mrp1);

    // }, []);

    // useEffect(() => {
    //     const saveToAllData = { allData, prd1, cs1, mrp1 }
    //     // console.log(allData, prd1, cs1, mrp1);
    //     window.localStorage.setItem("Products", JSON.stringify(saveToAllData));
    // });
    // useEffect(() => {
    //     const saveToAllData = { orderItem: order.orderItems, prd1, cs1, mrp1 }
    //     // console.log(allData, prd1, cs1, mrp1);

    //     window.localStorage.setItem('Products', JSON.stringify(saveToAllData));
    // }, [order.orderItems, prd1, cs1, mrp1]);


    function submitDatahandler(e) {
        e.preventDefault();
        const arr = order.orderItems.map(e => {

            return {
                SrNo: e.slno,
                wProdCode: e.PrdCode,
                qty: e.noOfquantity,
                free: 0,
                prodname: e.prd1,
                entryTime: e.showTime,
                rProdCode: '',
                pack: e.cs1,
                wBarcode: "",
                sourceId: 0,
            }
        });
        let senddata = order;
        //senddata.SrNo = ++e.slno;
        senddata.remarks = remarksMessage;
        senddata.orderItems = arr;
        axios.post("https://popsv3.relysoft.in/api/Orders/WritePopsOrders/",
            senddata
            , {
                headers: {
                    Authorization: "Bearer " + token.token,
                }
            }
        ).then(res => {
            console.log(res.data)

        }).catch(err => {
            console.error(err);
        })
        console.log(senddata)
        setData(senddata.SrNo + 1);

        // setOrder(
        //     {
        //         ...order, orderItems: order.orderItems.order + 1
        //     });
        setOrder(order + 1)
    }



    useEffect(() => {
        const CustomerOrders = window.localStorage.getItem("Customer-Orders" + customerOrder);
        const saveData = JSON.parse(CustomerOrders);
        console.log(CustomerOrders)
        console.log({ customerOrder })
        console.log(saveData);
        if (saveData) {
            if (saveData.orderItem) {

                setOrder((v) => {
                    return {
                        ...v,
                        orderItems: saveData.orderItem,
                        //   orderItems: saveData.orderItemorder.orderItems, cstOrders, cstorderPlace, prd1, cs1, mrp1

                    }

                });

            }

            cstOrders && setcstOrders(saveData.cstOrders);
            cstorderPlace && setCstOrderPlace(saveData.cstorderPlace);
            prd1 && setPrd1(saveData.prd1);
            cs1 && setCs1(saveData.cs1);
            mrp1 && setMrp1(saveData.mrp1);


        }
    }, [cs1, cstOrders, prd1, mrp1, cstorderPlace, customerOrder]);

    useEffect(() => {

        const saveToAllData = { orderItem: order.orderItems, cstOrders, cstorderPlace, prd1, cs1, mrp1 }
        console.log(order.orderItems);

        //window.localStorage.setItem('Customer-Orders' + customerOrder, JSON.stringify(saveToAllData));
        if (saveToAllData.orderItem && saveToAllData.orderItem.length > 0) {
            customerOrder && window.localStorage.setItem('Customer-Orders' + customerOrder, JSON.stringify(saveToAllData));
        }
    }, [order.orderItems, cstOrders, cstorderPlace, prd1, cs1, mrp1, customerOrder]);





    function logout() {
        window.localStorage.removeItem('user-info')
        //history.push('/Customer');
        window.location.href = "/SalesMan";
    }



    return (
        <>
            <SalesManHeader />

            <div className="UserDiv">
                <p>Hello!{user.name}</p>
                <button
                    className="backBTN"
                    onClick={() => history.goBack()}
                >Back</button>
                <button onClick={logout} className="logoutBTN">Log Out</button>
                <h6><span style={{ color: "rgb(64, 64, 64)" }}>Customer:</span>
                    <span style={{ color: "rgb(101, 100, 100)" }}>
                        {cstOrders + "," + cstorderPlace}
                    </span>
                </h6>
                {/* <h6>Customer:
                    {
                        savecst.map(a => customerOrderName)
                    }
                </h6> */}

            </div>

            <h1 style={{
                textAlign: "center",
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontSize: '28px',
                fontWeight: "600",
                padding: "20px"
            }}>Place  Order</h1>

            <div className="row" id="searchbar">
                <div className=" 
            " >

                    <Typeahead
                        //stock={stock}
                        style={{ border: "1px solid #66cccc", width: "1200px" }}
                        placeholder="Search Product"
                        id="searchProduct"
                        className="searchProduct"
                        labelKey="companyName"
                        name="companyName"
                        columns={productsColumns}
                        colWidth={window.screen.width}
                        isLoading={false}
                        //when typing in the text box search in handled here
                        onSearch={e => {
                            console.log(e);
                            setProductSeach({ ...productSeach, search: e });

                            SearchHandler(e)
                        }}
                        // when value is choosen or selected
                        onChange={e => {
                            console.log(e[0]);
                            // setProductSeach({ ...productSeach, selected: e });

                            const elm = e[0]
                            setSmShow(true);
                            setVisible(false)
                            setCompName(elm.companyName)
                            setProdName(elm.prodName)
                            setCase1(elm.case1)
                            setScheme(elm.scheme)
                            setStock(elm.stock)
                            setMrp(elm.mrp)
                            setRate(elm.rate)
                            setProductCode(elm.prodCode)

                        }}
                        options={newProduct ? newProduct : []}
                        selected={productSeach.selected ? productSeach.selected : []}
                    />
                </div>
            </div>




            <form onSubmit={submitDatahandler}>
                <div className="AddedTable">
                    <Table striped bordered variant=" ">
                        <thead>
                            <tr style={{ backgroundColor: "#00aff0", color: "white", border: "1px solid black" }}>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Packing</th>
                                <th>Scheme</th>
                                <th>MRP</th>
                                <th>Quantity</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody >

                            {
                                order.orderItems && order.orderItems.map((data, i) => {

                                    return (

                                        <tr key={i} style={{ border: "1px solid black" }}>
                                            <td>{data.slno}</td>
                                            <td>{data.prd1}</td>
                                            <td>{data.cs1}</td>
                                            <td>{data.sc1}</td>
                                            <td>{data.mrp1}</td>
                                            <td>{data.noOfquantity}</td>
                                            <td
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { deletehandler(data) }}
                                            >Delete <AiOutlineDelete style={{ marginLeft: "7px", color: "red" }} /></td>

                                        </tr>

                                    )
                                })
                            }
                        </tbody>

                    </Table>
                </div>




                <Modal
                    size="lg"
                    style={{ width: '95%' }}
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <Modal.Header closeButton style={{ backgroundColor: "#2EC8A6" }}>
                        <Modal.Title >
                            <span style={{ color: "white", fontWeight: "300" }}>{prodName}</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Container>



                            <Row>
                                <Col sm={8} style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#737373", fontWeight: "700" }}>{compName}</Col>
                                <Col sm={8} style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Scheme :  {scheme}</Col>
                            </Row>
                            <Row xs={1} md={4}>
                                <Col sm style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Packing :  {case1}</Col>
                                <Col sm style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Rate :  {rate}</Col>
                                <Col sm style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Stock :  {stock}</Col>
                                <Col sm style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}> {"  "}</Col>
                            </Row>
                            <Row xs={1} md={4}>
                                <Col style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>case :   -,-</Col>
                                <Col style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Min :  0</Col>
                                <Col style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>MRP :  {mrp}</Col>
                                <Col style={{ borderBottom: "1px solid rgb(217, 217, 217)", padding: "9px", color: "#757575", fontWeight: "500" }}>Quantity :
                                    <input
                                        type="number"
                                        // value={numbers}
                                        style={{ width: "100px" }}
                                        onChange={(e) =>
                                            setNoOfQuantity(e.target.value)
                                        }
                                        onKeyPress={addValuehandlerr}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Button


                        className="BtnsA"
                        //  (e) => (noOfquantity.trim() !== 0 ? alert(noOfquantity) : alert("empty number")) 
                        onClick={addValuehandler
                        }>Add Product</Button>
                </Modal>


                <div className="DropC">
                    <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>Priority</h5>
                    <select>
                        <option>Normal</option>
                        <option>Urgent</option>
                    </select>
                </div>
                <div className="remarks">
                    <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>Remarks</h5>



                    <div className="dropdowns">
                        <span className="dropdwnsName" >{value}{input}</span>
                        <div className="dropdown-content">
                            <li
                                name="PickUpOrder"
                                onClick={() => setValue(pickuporder)}
                                value="PickupOrder"
                                style={{ color: "rgb(89, 91, 91)" }}
                            >Pickup Order</li>
                            <li
                                onClick={() => setValue(deliveryOrder)}
                                value="Delivery Order"
                                style={{ color: "rgb(89, 91, 91)" }}
                            >Delivery Order</li>
                            <li
                                onClick={() => setInput()}
                                value="Remarks"
                                style={{ fontWeight: "bold" }}
                            >Remarks

                                <textarea name="Text1" cols="68" rows="2" onChange={(e) => setValue(e.target.value)}></textarea></li>
                        </div>
                    </div>
                    <h5 style={{ marginTop: "50px", marginBottom: "50px", fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>Approx Order Value : Rs.
                        {increaseprice}


                    </h5>
                </div>


                <button
                    type="submit"
                    className="SubmitButton"
                >Submit</button>

            </form>


        </>
    )
}