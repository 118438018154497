import { useEffect, useState } from "react";
import "./Styles/App.css";
import { Table, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Cproduct from "./C_product";
import GlobalVariable from "./GlobalVariable";
import ApiHandler from "./ApiHandler";
import LoaderTri from "./components/Loader/LoaderTri";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

function CompanyList() {
  const [data, setData] = useState([]);
  const [isProduct, setIsProduct] = useState(false);
  const [comCode, setComCode] = useState(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const cancelToken = ApiHandler.cancelToken;
    const source = cancelToken.source();

    ApiHandler.get({
      //url: localUrl + "api/OwnApp/GetComOrderList",
      url: "/api/POPSReports/AllCompanies/" + GlobalVariable.wcClient,
      cancelToken: source.token,
    })
      .then((result) => {
        const array = [];
        let count = 1;
        let temp = [];
        result.forEach((element) => {
          temp.push(element);
          count++;
          if (count === 4) {
            array.push(temp);
            temp = [];
            count = 1;
          }
        });
        console.log(array);
        setData(array);
      })
      .catch((err) => {
        console.error(err);
        if (
          err.response &&
          err.response.status &&
          err.response.status === 401
        ) {
          console.log(err.response);
          //   RefreshTokenHandler();
          //  ToastError("Token has expired Please refresh");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });

    return () => {
      source.cancel();
      setData([]);
    };
  }, []);

  return (
    <>
      {!isProduct && !comCode ? (
        <div className=" ">
          <h5 className="text-center mt-3">Company List</h5>
          <Button
            variant="primary"
            style={{
              float: "left",
            }}
            className="m-2 mt-4"
            onClick={() => {
              history.push("/");
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mx-2" />
            Back
          </Button>

          <div
            className=" "
            style={{
              width: "100%",
              overflowX: "scroll",
            }}
          >
            <Table striped>
              <tbody>
                {data.map((data, i) => {
                  return (
                    <tr key={i}>
                      {data.map((s) => (
                        <td
                          className="compa"
                          key={s.compCode}
                          style={{
                            color: "#2ec8a6",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            setComCode(s.compCode);
                            setIsProduct(true);
                            console.log();
                          }}
                        >
                          {s.name}{" "}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            {isLoading && <LoaderTri />}
          </div>
        </div>
      ) : (
        <Cproduct
          comCode={comCode}
          setComCode={() => {
            setComCode(null);
            setIsProduct(false);
          }}
        />
      )}
      <br />
      <br />
      <br />
    </>
  );
}

export default CompanyList